.matrix-layout {    
    padding:0px 0px 15px 15px;
    overflow-y:hidden;
    position: relative;
    .yAxis-Name,.xAxis-Name{
        position: absolute;
        width:auto;
        height:auto;
        left:0;
        display:block;
        text-align: center;
        font-size:14px;
        
    }
    .yAxis-Name{
        top:40%;
        transform: rotate(-90deg);
        left:-20px;
    }
    .xAxis-Name{      
        bottom:0px;
        right:0;
        left:15px;       

    }
    

    tr{
        td{
            padding:15px;
            position: relative;
            border:1px  solid #000;
            text-align:center;

            &:first-child{
                border:none;
                border-right:1px solid #000;
                transform: rotate(-10deg);
                font-size: 14px;                
            }
            span.appreciation {
               
                height: 30px;
                width: 30px;               
                display: flex;
                flex-flow: column;
                justify-content: center;
                border-radius: 50%;
                align-items: center;
                background-color: coral; 
                margin:0 auto; 

                &:hover{
                    border:1px solid red;
                    cursor: pointer;
                }     
                
            } 
        }
        &:last-child{
            td{
                border:none;
                border-top:1px solid #000;
                padding:15px 20px;
                width:100px;             
                transform: rotate(-10deg);
                font-size: 14px;

                &:first-child{
                    border-top:none;
                    
                    &::before{
                        display: none;
                    }
                }
            }
        }
    }    

}