@mixin vertical-center{
    justify-content: center;
    flex-flow: column;
    align-items: center;
}

// Bootstrap breakpoints variable and media queries using max-width 

$screen-xs : 576px;
$screen-sm : 767px;
$screen-md : 992px;
$screen-lg: 1200px;
$screen-tabh: 1024px;

@mixin break-xs{
    @media (max-width: #{$screen-xs}) {
        @content;
    }
}
@mixin break-sm{
    @media (max-width: #{$screen-sm}) {
        @content;
    }
}
@mixin break-md{
    @media (max-width: #{$screen-md}) {
        @content;
    }
}
@mixin break-lg{
    @media (max-width: #{$screen-lg}) {
        @content;
    }
}
@mixin break-tabh{
    @media (max-width: #{$screen-tabh}) {
        @content;
    }
}

@mixin bg-color($color){
    background-color: $color;
}