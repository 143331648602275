.histoloaderouter {
    width: 100%;
    overflow: hidden;
}
.main-loader-wrapper{
    display:flex;
}
.main-loader{
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: flex-end;    
    height: 100%;
    .loader__bar{
        min-width:30px;
        margin-left:20px;
        float:left;
        height:100%;
      
        &:nth-child(1){
            height:calc(100% - 40% );
        }
        &:nth-child(2){
            height:calc(100% - 50% );
        }
        &:nth-child(3){
            height:calc(100% - 20% );
        }
        &:nth-child(4){
            height:calc(100% - 80% );
        }
        &:nth-child(5){
            height:calc(100% - 50% );
        }
        &:nth-child(6){
            height:calc(100% - 35% );
        }       
        &:nth-child(7){
            height:calc(100% - 25% );
        }
        &:nth-child(8){
            height:calc(100% - 25% );
        }
        &:nth-child(9){
            height:calc(100% - 69% );
        }
        &:nth-child(10){
            height:calc(100% - 40% );
        }
        &:nth-child(11){
            height:calc(100% - 55% );
        }
        &:nth-child(12){
            height:calc(100% - 66% );
        }
        &:nth-child(13){
            height:calc(100% - 44% );
        }
        &:nth-child(14){
            height:calc(100% - 75% );
        }
        &:nth-child(15){
            height:calc(100% - 57% );
        }
        &:nth-child(16){
            height:calc(100% - 42% );
        }
        &:nth-child(17){
            height:calc(100% - 49% );
        }
        &:nth-child(18){
            height:calc(100% - 66% );
        }
        &:nth-child(19){
            height:calc(100% - 48% );
        }
        &:nth-child(20){
            height:calc(100% - 61% );
        }
        &:nth-child(21){
            height:calc(100% - 79% );
        }
        &:nth-child(22){
            height:calc(100% - 68% );
        }
        &:nth-child(23){
            height:calc(100% - 39% );
        }
        &:nth-child(24){
            height:calc(100% - 25% );
        }
        &:nth-child(25){
            height:calc(100% - 74% );
        }
        &:nth-child(26){
            height:calc(100% - 36% );
        }
        &:nth-child(27){
            height:calc(100% -46% );
        }
        &:nth-child(28){
            height:calc(100% - 66% );
        }
        &:nth-child(29){
            height:calc(100% - 85% );
        }
        &:nth-child(30){
            height:calc(100% - 40% );
        }
        &:nth-child(31){
            height:calc(100% - 70% );
        }
        &:nth-child(32){
            height:calc(100% - 32% );
        }
        &:nth-child(33){
            height:calc(100% - 48% );
        }
        &:nth-child(34){
            height:calc(100% - 65% );
        }

    }
    
}
