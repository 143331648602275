*{
    margin:0px;
    padding:0px;
}
body {
    
   // background-color: #cecbcb;
   overflow-x:hidden;
   @include montserrat-regular;
   font-size:16px;
   color:$text-primary;
}
h1,h2,h3,h3,h4,h5,h6{
    @include montserrat-medium;
    margin:0px;
}
.btn{
    padding:.25rem 2rem;
    border-radius: 30px!important;
    border: none !important;
    width: 100%;
    &:hover{
        border:none!important;
    }
    &.btn-primary{
        color:$white;
        background-color:$primary-color !important;
        &:focus{
            box-shadow:unset;
        }
    }
    &.btn-secondary{
        color:$white;
        background-color:$secondary-color-1;
        &:focus{
            box-shadow:unset;
        }
    }
    &:focus{
        box-shadow:unset;
    }
}

p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.3em;  
    margin-bottom: 0px;
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}
ul,li{
    list-style-type: none;
    padding:0px;
    margin:0px;
}
.hide{
    display: none!important;
}

.wrapper{
    display:flex;
    width:100%;
    align-items:stretch;
    flex-wrap: wrap;  
}

.body-container {
    display: flex;
    padding-top: 60px;
    width: 100%;
    position: relative;
    @include break-xs{
        padding-top:50px;
    }

}
.dashBoardOverlay{
    position: fixed;
    left: 0;
    top: 64px;
    right: 0;
    bottom: -64px;
    height:100%;
    width:100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1024;
    @include break-xs{
        top: 50px;
        bottom:-50px;
    }
}
.filterOverlay {
    position: fixed;
    left: 0;
    top: 60px;
    right: 0;
    bottom: -64px;
    height:100%;
    width:100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1025;
    @include break-xs{
        top: 50px;
        bottom:-50px;
    }
}
.outer-container {   
    width:100%;
    height: auto;
    background-color: $white;   
    height:auto;    
    padding-left:230px;   
    -webkit-transition: all 750ms ease-in-out; /* For Safari 3.1 to 6.0 */
    transition: all 750ms ease-in-out;
    @media screen and (max-width:992px){        
        transition:none;
        padding-left:0px;
    }
    &.active{
        position: relative;
        padding-left:87px;       
        width:100%;     
        -webkit-transition: all 750ms ease-in-out; /* For Safari 3.1 to 6.0 */
        transition: all 750ms ease-in-out;    
        @media screen and (max-width:992px){     
            padding-left:0px;   
        }
    }
    .bar-chart {
        display: flex;
        background-color: #fff;
        justify-content:center;;
        padding: 25px 25px;
        @media screen and (max-width:575px){
            margin-bottom:25px;
        }
    }
}
// .node rect {
//     fill: #fff;
//     stroke: steelblue;
//     stroke-width: 3px;
//   }
  
  .node text {
    font: 12px sans-serif;
  }
  
//   .link {
//     fill: none;
//     stroke: #ccc;
//     stroke-width: 2px;
//   }

  .singleBarChart {
    fill: steelblue;
  }
  
  .singleBarChartHighlight {
    fill: orange;
}

.doubleBarOneChart {
    fill: steelblue;
  }
  
  .doubleBarChartOneHighlight {
    fill: orange;
}
.doubleBarTwoChart {
    fill: orange;
  }
  
  .doubleBarChartTwoHighlight {
    fill: steelblue;
}

.arc path {
    stroke: #fff;
  }

  .singleBarChart {
    fill: steelblue;
  }
  
  .singleBarChartHighlight {
    fill: orange;
}

.doubleBarOneChart {
    fill: steelblue;
  }
  
  .doubleBarChartOneHighlight {
    fill: orange;
}
.doubleBarTwoChart {
    fill: orange;
  }
  
  .doubleBarChartTwoHighlight {
    fill: steelblue;
}

.arc path {
    stroke: #fff;
  }




  //------ form styling goes here ----- 
    
input:-internal-autofill-selected{
    background-color: transparent;
}
form{
    width:100%;
    .form-group{
        margin-bottom: 15px;
        .form-control{
            background-color: transparent!important;
            border: none;
            border-bottom: 1px solid $lighter-gray!important;
            border-radius:0px;
            &:focus{
                box-shadow:unset;
            }

        }
    }
    button{
        &.btn{
            float: right;
            margin-top: 30px;
            border-radius: 0px;
            padding: .375rem 2rem;
            @include montserrat-semibold;
        }  
    }
}

// breadcrum module css goes here 

.breadcrumModule .breadcrumb {
    padding: 0px;
    margin-bottom: 0;
    list-style: none;
    background-color: transparent;
    border-radius: 0px;

    .breadcrumb-item a {
        text-decoration: none;
        background-color: #f1b1b7;
        padding: 5px 10px;
        color: #fff;
    }
}
  
.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    padding-left: .5rem;
    color: #6c757d;
    content: ">";
}
.myTagCloud {
    .tag-cloud-tag{
        color: black !important;
        cursor: pointer;
        &:hover {
            color: red !important;
        }
    }
}

.nopad{
    padding-left:0px;
    padding-right:0px;
}


.bodyOverlay{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1024;
    height:100%;
    width:100%;
   
}

.eventModalContainer {
    position: absolute;
    padding: 30px;
    background-color:$white;
    top: 50%;
    left: 50%;  
    border-radius:5px;
    z-index:1031;
    transform: translate(-50% , -50%);
    .closebutton{
        position: absolute;
        right:30px;
        top: 13px;
        width: auto;
        height: auto;        
        font-size: 34px;       
        color: #c3c3c3;
        font-weight: 600;
        cursor: pointer;

    }
    &.graphModalContainer{
        position: fixed;
        height:90%;
        width:90%;
        top: 5%;
        left: 5%;    
        transform: unset;
        margin:0 auto; 
        .modalgraphWrapper{
            div{
                text-align: center;
            }
            
        }
    }
    .eventModalTitle,
    .eventModalBodyContent {
        float: left;
        padding-bottom: 10px;
        width: 100%;
        text-align:center;
    }
    .eventModalTitle{
        @include montserrat-semibold;
        font-size:14px;
        text-transform:capitalize;
    }
    .eventModalBodyContent {
        @include montserrat-regular;
        font-size:14px;
        overflow: auto;
        max-height: 280px;
        padding-bottom:10px;
    }
    
    .eventModalControl{
        width:100%;
        text-align:center;;        
        .btn{
            width: 50%;  
            padding: 5px 15px;
        }
        &.prompt{
            display:flex;
            justify-content: space-between;
            .btn{
                width: 30%;
                margin-top: 10px;               
            }
        }
    }
    .graphPreventsclick {
        position: absolute;
        background-color: transparent;
        top: 50px;
        right: 0px;
        left: 0px;
        bottom: 50px;
    }
    .barcharModalTitlecontainer{
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
    }
    .actionbtnContainer{
        align-items: baseline;
    }
   
}
////////////////////////////////////////LOADER///////////////////////////////////

@keyframes placeHolderLoader{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
@keyframes placeHolderLoaderLg{
    0%{
        background-position: -800px 0
    }
    100%{
        background-position: 1600px 0
    }
}
@keyframes placeHolderLoaderDelay{
    0%{
        background-position: -800px 0
    }
    20%{
        background-position: -1200px 0
    }
    100%{
        background-position: 1600px 0
    }
}
.animated-background {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderLoader;
    animation-timing-function: linear;
    background: #ededed;
    background: linear-gradient(to right, #d2cece 8%, #e6e1e1 22%, #d2cece 36%);
    background-size: 800px 104px;
    position: relative;
    
}

.animated-background.lg-width{
    animation-duration: 2s;
    animation-name: placeHolderLoaderLg;
    background:linear-gradient(to right, #d2cece 8%, #e6e1e1 22%, #d2cece 36%);
    background-size: 1200px 104px;
}
.animated-background.lg-width.delay{
    animation-delay: 0.35s;
}

h2,h3{
    font-size: 26px;
    @include montserrat-medium;
    line-height:26px;
    // text-transform:capitalize;
    margin-bottom:20px;
    padding:0px;
    color:$secondary-color-1;
    @include break-sm{
        font-size:20px;
    }

}
h3{
    @include montserrat-semibold;
    font-size: 18px; 
    color:$secondary-color-3;
    @include break-sm{
        font-size:16px;
    }

}
.actionbtnContainer{
    display:flex;
    justify-content: space-between;
    align-items: center;
    .btn{
        background-color:transparent;
        padding:0px;
        margin-left:15px;
        border-radius: 0px !important;
    }
}

.modalOverlay{
    position: fixed;
    height:100%;
    width:100%;
    top:0;
    left:0px;
    right:0;
    bottom:0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1030;
}
.spiderloader{
    width:100%;
    padding: 25px 15px;
    .spidercontainer{
        text-align: center;
        img{
            @include break-md{
                height:350px;
            }
            @include break-xs{
                height:250px;
            }
        }
    }
    .barchartContainer{
        margin-bottom:50px;
        @include break-md{
            margin-top:50px;
            text-align: center;
        }
        
    }
}
.recharts-wrapper{
   width:100% !important;
   @include break-xs{
       overflow-x: scroll;
    }
    
}
    

// .wordCloudWrapper {
// 	display: flex;
// 	flex-direction: column;
// 	height: 100%;
// 	max-width: 1000px;
// 	width: 100%;
// }

.tag-cloud {
	flex: 1;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.app-outer {
   align-items: center; 
   bottom: 0;
   display: flex;
   flex-direction: column;
   justify-content: center;
   left: 0;
   padding: 20px 0;
   position: absolute;
   right: 0;
   top: 0;
//    width: 800px;
//    height: 100%;
}

.app-inner {
   display: flex;
   flex-direction: column;
   height: 100%;
   max-width: 1000px;
   width: 100%;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus{
    box-shadow:unset;
    background-color: $secondary-color-1;
}


.custom-control-label{
    cursor:pointer;
    @include montserrat-medium;
    font-size:16px;
    color:$secondary-color-1;
}

.custom-radio .custom-control-label::before {
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid $lighter-gray;
}
.custom-control-label::after{
    background-color: transparent;
    top: 8px;
    left: -20px;
    display: block;
    width: 0.5rem;
    height: 0.5rem;
}
.custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: transparent;
    border: 2px solid #64c9d3;
}
.custom-radio .custom-control-input:checked~.custom-control-label::after{
    border-radius: 50%;
    background-color: #64c9d3;
    background-image:none!important;
}
html{
    scroll-behavior: smooth;

}

.recharts-legend-item-text {
    font-size: 15px;
    padding-left: 0px;    
    color:#595959;
    @include montserrat-medium;
}
table{

    th{
        @include montserrat-semibold;
        font-size: 16px;
        background-color: #f2f2f0;
        border:none!important;

    }
    td{
        @include montserrat-medium;
        font-size: 16px;
        border:none!important;
        &:nth-child(2){
            text-align: center;
        }
        
    }
    tbody{
        tr{
            cursor: pointer;
            &:nth-child(even){
                background-color: #faf9f7;
            }
            border:none!important;            
        }
    }

}

.tableModule.table-responsive{
    display:block;
    max-height:450px;
    overflow:auto;
}

.tableskeleton{    
    display: block;
    table{
       width:100%;        
        td{           
           border:2px solid $white!important;
            height:50px;
        }
    }
}
//////////////
// .slice {
//     cursor: pointer;
// }

// .slice .main-arc {
//     stroke: #fff;
//     stroke-width: 1px;
// }

// .slice .hidden-arc {
//     fill: none;
// }

// .slice text {
//     pointer-events: none;
//     dominant-baseline: middle;
//     text-anchor: middle;
// }
.footerwaper{
    padding:22px 30px;
    background-color:#eee;
    width:100%;
    color:$text-primary;
    font-size:12px;
    text-align: left;
    border-top: 1px solid #c3c3c3;

}
.pichartLoaderOuter{
    width:500px;
    height:500px;
    border-radius:50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    .pichartInner{
        padding:80px;
        width:220px;
        height:220px;
        border-radius:50%;
        border:2px solid $white;

    }

}

.redemptionPichartInner{
    width:420px;
    height:420px;
    border-radius:50%;
    border:2px solid $white;
    margin: 0 auto;
}

.treemapLoader{
    width:100%;
    height:400px;
}

.custom-tooltip{
        margin: 0px;
        padding: 10px;
        background-color: rgb(255, 255, 255);
        border: 1px solid rgb(204, 204, 204);
        white-space: nowrap;
        @include montserrat-medium;
        font-size: 12px;
        p{
            text-align: left;
            @include montserrat-medium;
            font-size: 12px;
            &:first-child{
                @include montserrat-semibold;
                font-size: 12px;
            }
        }
}
.recharts-tooltip-label {
    @include montserrat-semibold;
    font-size: 12px;
}
.gotopbutton{
    position: fixed;
    width: 50px;
    height: 50px;
    right: 15px;
    bottom: 15px;
    cursor: pointer;
}
@keyframes btn-loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}


input[type=number].react-inputs-validation__textbox__input___20hDL,
 input[type=password].react-inputs-validation__textbox__input___20hDL, 
 input[type=phone].react-inputs-validation__textbox__input___20hDL, 
 input[type=text].react-inputs-validation__textbox__input___20hDL{
     border:1px solid transparent !important;
     border-bottom: 1px solid #c2c2c2!important;
 }
 input[type="text"].react-inputs-validation__textbox__input___20hDL.react-inputs-validation__error___2aXSp,
 input[type="password"].react-inputs-validation__textbox__input___20hDL.react-inputs-validation__error___2aXSp{
    border: 1px solid #ff5a57!important;
 }
 .graphNodeTitle{
    display:flex;
    justify-content: space-between;
    padding:0px 15px 20px;
    
    p{
        @include montserrat-semibold;
        font-size: 14px;
        color:$text-primary;
        padding: 10px 50px;
        background-color: #adadad;
    }
}
.modalgraphWrapper{
    .graphNodeTitle{       
           margin: 0 auto;
    }    
}

.scroll-auto {
    overflow: auto
}

.multi-select__control {
    .multi-select__value-container{
        padding: 4px 8px;
    }
}