// @import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600&display=swap');

@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

//font-family: 'Roboto', sans-serif;
//font-family: 'Montserrat', sans-serif;


@mixin montserrat-light{
    font-family: 'Roboto', sans-serif;
    font-weight:300;
}
@mixin montserrat-regular{
    font-family: 'Roboto', sans-serif;
    font-weight:400;

}
@mixin montserrat-medium{
    font-family: 'Roboto', sans-serif;
    font-weight:500;
}
@mixin montserrat-semibold{
    font-family: 'Roboto', sans-serif;
    font-weight:700;

}