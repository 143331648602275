.tabContainer{
    width:100%;
    .tablinkouter{
        display:flex;
        justify-content: space-between;
        border-bottom: 1px solid $light-gray;
    }
    .nav{
       //justify-content: space-between;
      
        &.nav-tabs{
            border-bottom: 1px;
            .nav-item{
                cursor: pointer;
                .nav-link{
                    border-radius:0px;
                    background-color:$light-gray;
                    color:$text-primary;
                    @include montserrat-semibold;
                    font-size:16px;
                    margin-left: 10px;
                    @include break-xs{
                        font-size:13px;
                        padding: .5rem 0.5rem;
                    }
                   
                    &.tab-recive{
                        span{
                            color:$tab-recive;    
                        }
                        
                    }
                    &.tab-given{
                        span{
                            color:$tab-given;   
                        }
                       
                    }
                    &.active{
                        background-color:transparent; 
                        &.Show{
                           
                        }
                    }
                   
                }
                .disabled{
                    background-color:transparent;
                }
                &:first-child{
                    .nav-link{
                        margin-left: 0px;
                    }     
                }  
            } 
                    
            
        }
    }
    .rightNav{
        li{
            a{
                color:$linkblue;
                @include montserrat-semibold;
                font-size:16px;
                @include break-xs{
                    font-size:13px;
                    padding: .5rem 0.5rem;
                }
                &:hover{
                    border:none;
                }
            }
        }
    }
    .tab-content{
        .tab-pane {    
            width:100%; 
            @include break-xs{
                font-size:13px;            
            }
            .appreciationList{
                padding: 20px 15px;
                display:flex;
                flex-flow:column nowrap;
                justify-content: center;
                width:100%;
                height:auto;
                &:nth-child(even){
                  background-color: $list-highlight;
                }
                .appreciation{
                    width:100%;
                    padding-bottom: 10px;
                  
                    p{
                        @include montserrat-medium;
                        @include break-xs{
                            font-size:13px;            
                        } 
                    }
                }
                .appreciationInfo{
                    display:flex;
                    width:100%;
                    justify-content: space-between;
                    .pointsCount{
                        @include montserrat-medium;
                        font-size:14px;
                        @include break-xs{
                               
                            font-size:12px;        
                        } 
                        span{
                            @include montserrat-semibold;
                            font-size:16px;
                            @include break-xs{                               
                                font-size:12px;        
                            } 
                        }
                    }
                    .Info{                        
                        display:flex;
                        justify-content: space-between;
                        .personName{
                            @include montserrat-medium;
                            font-size:14px;
                            @include break-xs{                               
                                font-size:10px;        
                            } 
                            span{
                                color:$linkblue;
                            }
                        }
                        .date{
                            padding-left:30px;
                            @include montserrat-medium;
                            font-size:14px;
                            @include break-xs{
                                padding-left:5px; 
                                font-size:10px;        
                            } 
                            span{
                                color:$linkblue;
                            }
                            
                        }
                    }
                }
            }
        }
    }
   
}


.tabskeleton{   
    padding:10px;
    .tabskeltontabOuter {
        display: flex;
        .tabbtn {
            width: 130px;
            height: 50px;
            margin-right: 10px;
        }
    }    
    .tabRow {
        width: 100%;
        height: 80px;
        margin-top: 10px;
    }
}

.dataNotFound{
    padding:20px 15px;
    @include montserrat-semibold;
    color: #c3c3c3;
    text-align: center;
    width: 100%;
    min-height: 250px;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;

}