.selectInput{
    position: relative;
    .selectInputEvent{
        position: absolute;
        left:0px;
        right:0px;
        top:0px;
        bottom:0px;
        background-color:rgba(255,255,255,0);     

    }
    
    .css-2b097c-container{
         .css-yk16xz-control,.css-1pahdxg-control{
            background-color: transparent;
            border: none;
            border-bottom: 1px solid $lighter-gray;
            border-radius: 0px;
            outline:none;
            box-shadow:unset;
            @include break-xs{
                font-size: 12px;
            }
            .css-1hwfws3{                 
                border-right:none;
                padding: 4px 0px;
                .css-1wa3eu0-placeholder{

                }
                .css-1rhbuit-multiValue{
                    .css-12jo7m5{

                    }
                    &:nth-child(n + 4){
                        display:none;
                    }
                    display:none;
                }
            }
            .css-1g48xl4-IndicatorsContainer{
                span{
                    display:none;
                }    
                .css-tlfecz-indicatorContainer{
                    color:#000;
                }
            }
            .css-1okebmr-indicatorSeparator {
                display: none!important;
            }

         }  
         .css-26l3qy-menu{
             z-index:1026;
            
         } 
    }
    .SelectedInput{
        border:1px solid #c0bcbc;
        padding:10px 5px 5px;
        display: flex;
        flex-wrap :wrap;
        justify-content: baseline;
        position: absolute;
        width: 100%;
        background-color: #fff;
        z-index: 1025;
        height:auto;
        display:none;
        &.show{
            display:block;
            .clear-btn{
                width: 20px;
                height: 20px;
                float: right;
                cursor: pointer;
                text-align: center;
                position: absolute;
                right: 5px;
                top:2px;
                img{
                    margin-top: -10px;
                    width: 11px;
                }
            }
        }
        span{
            background-color:$light-gray;
            display: flex;
            float: left;
            border-radius:30px;
            font-size: 12px;
            line-height:12px;
            margin-right: 5px;
            margin-top: 10px;     
            padding: 5px 5px 5px 10px;
            justify-content: space-between;
            align-items: center;   
            width:100%;
            color: $text-primary;

            &:nth-child(n + 4){
                display:none;
            }

            span{                
                width: 25px;
                padding: 0px 0px 0px 5px;
                border-radius: 0px;
                font-size: 12px;
                line-height: 12px;
                margin: 0px;
                cursor: pointer;
                &.chipsclear {
                    text-align: center;
                    font-size: 16px!important;
                    color: $text-primary;
                }
                
            }
            &.invalidSelection{
                border:1px solid $primary-color;
                color: $white;
                background-color: $primary-color;
                span{
                    background-color: $primary-color;
                }

            }
        }
        .moreBtn{
            background-color:$secondary-color-1;           
            float: left;
            border-radius:30px;
            font-size: 12px;
            margin-right: 8px;
            margin-top: 10px;    
            padding: 5px 10px;
            line-height:12px;
            color:$white;
            cursor:pointer;
        }
        
        &.showMore{
            height: auto;           
            span{
                &:nth-child(n + 4){
                    display:flex;
                }
            }
            .moreBtn{
                display:none;
            }
            .clear-btn{
                width: 20px;
                height: 20px;
                float: right;
                text-align: center;
                cursor: pointer;
                position: absolute;
                right: 5px;
                img{
                    margin-top: -10px;
                    width: 11px;
                }
            }
        }
        @media (max-width:1550px){
            position: relative;
            float:left;
        
        }

    }
}