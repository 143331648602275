#header-nav{
    width: 100%;
    height: auto;
    transition: all 0.3s;
    position: fixed;
    z-index: 1026;
    &.active {
       
    }
    .navbar.main-nav{
        background-color:$primary-color;
        padding:0px;
        @include break-xs{
            display: flex;
            flex-wrap: nowrap;
            height: 50px;
            width:100%;

        }
        .left-nav{
           display:flex;  
           align-items: center;
           .navbar-toggler-icon {
            background-image: none!important;
           }
        }
        .btn{           
            left: 25px;
            top: 80px;
            z-index: 9999;
            padding: 0px;
            position: absolute;
            width: auto;     
            background-color:transparent;
            @include break-md{
                padding: 0px 5px 0px 15px;
                position: relative;
                top: 5px;
                background-color: transparent;
            }
            &.focus, 
            &:focus{
                box-shadow:none;
            }
            .redhambeurger{
                @include break-md{
                    display:none;
                }
            }
            .whitehambeurger{
                display:none;
                @include break-md{
                    display:block;
                }

            }

        } 
        .navbar-brand{
            @include break-xs{
              margin-right:0px;
            }
            @include break-md{
                position: absolute;
                left:35%;
            }
            @include break-sm{
                left:27%;
            }
            @include break-xs{
                left:25%;
            }
            img{
                height:40px;               
                @include break-sm{
                    height:30px;
                }
            }
        }   
        
        .title-name{
            padding-left:50px;
            p{
                @include montserrat-medium;                
                font-size:24px;
                margin-bottom:0px;
                color: $white;

            }        


        }
        .navbar-right{
            padding:0px;
            ul.right-nav{              
            
                li{
                    display:inline-block;               
                    text-transform: capitalize;
                    padding:15px;
                    a{
                        text-transform: capitalize;
                        font-size:16px;
                        padding:10px 15px;
                        @include montserrat-medium;
                        margin-bottom:0px;
                        color: $white;
                        @include break-xs{
                            padding:10px 0px 10px 0px; 
                            font-size:12px;
                        }
                        
                        
                    }

                    &.filter-btn{
                        padding:0px 10px 0px 0px;
                        cursor: pointer;
                        @include break-xs{
                            padding:0px;
                        }  
                        img{
                            height: 40px;
                            width:40px;
                            @include break-xs{
                                height:30px;
                                width:30px;
                            }  
                        }
                    }
                   &.dropdown{
                       padding:10px;
                       .dropdown-menu {
                            margin-top: 0px;
                            background-color: #efeded;
                            border-radius: 0px;
                            border:none;
                            background-color:$secondary-color-5; 
                            margin-top: -1px;                           
                            .dropdown-item{
                                color:$secondary-color-1!important;
                                padding:5px 10px;
                            }    
                        }
                       &.profile-tab{                       
                            .dropdown-toggle{  
                               
                                &:after{
                                    display:none;
                                }                     
                                padding:0px;                    
                                img{
                                    &.round-img{
                                        border-radius: 50% !important;
                                        height: 40px;
                                        width: 40px;
                                        @include break-xs{
                                            height:30px;
                                            width:30px;
                                        }    
                                        
                                    }
                                }
                            }
                            .dropdown-menu{
                                width:300px;
                                background-color:$secondary-color-5;
                                margin-top: 1px;
                                .dropdown-item{                                   
                                    &:hover,
                                    &:focus{
                                        background-color:transparent;
                                    }                                   
                                }
                                .profileSeting{
                                    display:flex;
                                    padding: .25rem 0.5rem;
                                    align-items: baseline;
                                    .profile-img{
                                        img{
                                            border-radius: 50% !important;
                                            height: 40px;
                                            width: 40px;  
                                            @include break-xs{
                                                height:30px;
                                                width:30px;
                                            }                                         
                                        }
                                        
                                    }
                                    .personal-info{
                                        flex: 1 1 auto;
                                        padding-left:10px;
                                      
                                        .name{
                                            p{
                                                font-size:15px;
                                                text-transform:capitalize;
                                                color: $secondary-color-1;
                                                margin-bottom: 0px;
                                                
                                            }
                                            display:none;
                                        }
                                        .email{
                                            p{
                                                font-size:12px;
                                                color: $secondary-color-1;
                                                margin-bottom: 0px;
                                                text-transform: initial;
                                            }
                                        }
                                    }

                                }
                                .profileSeting-btns{
                                    display:flex;
                                    padding: .25rem 0.5rem;
                                    justify-content: space-between;
                                    padding-top:15px;
                                    a{
                                        width:auto!important;
                                        font-size: 12px;
                                        padding: 8px 18px;
                                        background-color: $primary-color;
                                        color:$white;
                                        border-radius: 0px;
                                        font-weight: 500;
                                        text-transform: uppercase;
                                        border:none;
                                        position: unset;
                                        @include break-xs{
                                            padding:5px 10px;
                                        }
                                        &:hover,
                                        &:focus{
                                            color:$white;
                                        }
                                        

                                    }
                                }
                                
    
                            }
                       }
                       
                       
                   }
                }
            }
        }
        
    }
}