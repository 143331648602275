.tilesSection {
    width: 100%;
    padding: 0px 30px 0px;
    .col-lg-3, .col-lg-6, .col-lg-4 {
        margin-top:30px;
        // @media (min-width:1450px){
        //    max-width:16.666%;
        // }
       
    }
    .animated-background {
        min-height: 50px;
    }
    @include break-md{
        padding:20px 15px;
    }
}
.tab-section {
    width: 100%;
    padding: 15px;
    
}
.appreciationsWithin{
    padding:70px 0px 0px;
    margin-left:-30px;
    margin-right:-30px;
    [class*='col-']{
        padding-left:30px;
        padding-right:30px;
    }
    @include break-md{
        padding:35px 0px 0px;
    }
    .graphsActionContainer{
        padding-bottom: 0px!important;
        h3{
           height:50px;
           line-height:21px;
        }
    }
    
}
.histogramSection {
    width: 100%;
    padding: 50px 30px;
    // margin-bottom:50px;
    // border-bottom: 2px solid #e4e4e4;
    @include break-md{
        padding:30px 15px;
    }
    .titleOuter{
        h2{
            margin-bottom:40px;
            span{
                text-transform: none;
            }
        }
        
    }
   .graphsActionContainer{
       padding-bottom:25px;
   }
    #mainHistogram{
        width:100%;
        overflow-x:auto;
    }
    
    .appreciationsWithin{
        .recharts-wrapper{
            svg{
                .recharts-layer.recharts-bar-rectangles{
                    cursor: pointer;
                    
                }
            }
            .recharts-legend-wrapper{
                @media screen and (max-width:1400px){
                    width:100%!important;
                    left:0px!important;
                }    
            } 
        }    
    }

} 
.graphsActionContainer{
    display: flex;
    justify-content: space-between;  
}
.backbtnContainer{

    .btn{
        width:auto;
        background-color: transparent;
        padding:1rem 0rem;
        @include montserrat-semibold;
        font-size:14px;
        img{
            padding-right:10px;
        }
    }
}
.titlesAndLegend{
    padding:15px 0px;

    h3{
        margin-bottom:0px;
    }
}

.topExport{
    display:flex;
    padding: 30px 30px 0px;
    justify-content: flex-end;
    width: 100%;
    @include break-md{
        padding:25px 15px 10px;
    }
    .exportPdfbtn{
        &.btn-loader{
            padding-right:40px;
            position: relative;
            &:after {
                content: " ";
                display: block;
                width: 26px;
                height: 26px;
                position: absolute;
                top: 0px;
                right: 0px;
                bottom: 0;
                margin: auto;
                border-radius: 50%;
                border: 4px solid #ea2c2d;
                border-color: #ea2c2d transparent #ea2c2d transparent;
                border-left-color: rgba(245, 152, 152,0.6);
                border-right-color: rgba(245, 152, 152,0.6);
                -webkit-animation: btn-loader 800ms linear infinite;
                -moz-animation: btn-loader 800ms linear infinite;
                -ms-animation: btn-loader 800ms linear infinite;
                animation: btn-loader 800ms linear infinite;

            }
            .btn{
                pointer-events: none;
                cursor: auto;
            }    
        }
        .btn{
            color:$primary-color;
            @include montserrat-semibold;
            font-size:14px;
            margin-left:0px;
            img{
                padding-left: 20px;
            }


        }
    }
}
.showInfo{
    position: relative;
    &:before{
        content:"";
        position: absolute;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;            
        border-bottom: 15px solid #0b2d44;
        bottom:-18px;
        display:none;
        
    }
    .infoMessage {
        position: absolute;
        width: 180px;
        height: auto;
        border-radius: 10px;
        background-color:#0b2d44;
        padding: 15px;
        top: 40px;
        left: -125px;        
        display:none;
        z-index:1024;
        p{
            color:$white;
            font-size: 13px;
            @include montserrat-regular;
            text-align: center;
            white-space: normal;
            text-overflow: ellipsis;
            overflow: hidden;
            
        }
        button.btn.closeBtn {
            color:$primary-color;
            width: 15px;
            height: 15px;
            position: absolute;
            right: 6px;
            @include montserrat-semibold;
            top: 6px;
            font-size: 12px;
        }
       
    }
    &.showmessage{
        .infoMessage{
            display:block;
        }
        &:before{
            display:block;
        }
    }
}
.graphInfoNote{
    width:100%;
    text-align: left;
    color:$para-primary;
    @include montserrat-medium;
    font-size: 14px;
    font-style: italic;    
}
.centerOfInfluence{
    width:100%;
    padding:100px 30px 40px;
    border-top: 2px solid $light-gray;
    .row{
        margin-left:-30px;
        margin-right:-30px;
        [class*='col-']{
            padding-left:30px;
            padding-right:30px;
        }
    }
    
    .titleOuter{
        h2{
            margin-bottom:50px;
            span{
                text-transform: none;
            }
        }
    }
    .graphInfoNote{
        padding-bottom:40px;
    }
    .sunbustLegend {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .legendItem {
            display: flex;
            align-items: baseline;
            margin-right: 10px;
            label {
                margin: 3px;
                font-size: 15px;
                padding-left: 0px;
                color: #595959;
                font-family: 'Roboto', sans-serif;
                font-weight: 500;
            }
        }
    }

    .cloud-container{
        position: relative;
        width:100%;
        height:100%;
        .cloudLoader.animated-background {           
            width:100%;
            height:300px;
        }

        ul.strengthName {
            display: flex;
            flex-wrap: wrap;
            li{
                padding:5px 15px 0px 0px;
                label{
                    font-size: 15px;
                    padding-left: 0px;
                    color: #595959;
                    font-family: 'Roboto', sans-serif;
                    font-weight: 500;
                }
            }
        }
    }
    .tableTitleContainer{
        display: flex;
        justify-content: space-between;
        padding-bottom:30px;
        h3{
            margin-bottom: 0px;

        }
    }
    .bystrenghDeptDrillDown{
        padding-top: 30px;
        .graphsActionContainer{
            span{
                text-transform: lowercase;
            }
        }
    }
    .by-rounders{
        width:100%;
        padding-top: 80px;
        .switchBtnouter{
            width:auto;
            margin-left:20px;
             // float: left;
            label{
                display:flex;
                flex-flow:column nowrap;
                span{
                    font-size:14px;
                    @include montserrat-medium;
                    color: $primary-color;
                    padding-bottom:10px;
                }
            }
        }
        .titlewithSlider {
            display: flex;

        }
        .inputsliderOuetr{
            width:50%;
            height: auto;
            padding-bottom: 50px;
            padding-top: 45px;
            // float: left;
            .rc-slider.rc-slider-with-marks{   
                .rc-slider-rail{
                    height:5px!important;
                    background-color: #e3e3e3;
                }         
                .rc-slider-handle{
                    width: 21px;
                    height: 21px;
                    border: solid 4px #0f3853;
                    margin-top: -8px;
                }
                
                .rc-slider-mark{
                    top:-30px;
                    .rc-slider-mark-text{
                        @include montserrat-semibold;
                        font-size: 16px;
                        color: #e3e3e3;
                        &.rc-slider-mark-text-active{
                            color:#426278;
                        }
                    }
                }
            }
            
        }
        .multiSelectwithlabel{
            padding-bottom: 50px;
            padding-top: 0px;
            display: flex;
            align-items: baseline;
            justify-content: flex-start;
            width:50%;
            label{
                @include montserrat-medium;
                color:$secondary-color-1;
                font-size:16px;
            }
            .selectInput {
                width: 70%;
                margin-left: 20px;
                .css-2b097c-container {
                    border:1px solid #c2c2c2;
                    .css-yk16xz-control{
                        border-bottom:none;
                    }
                }
                .SelectedInput{
                    position: relative;
                    z-index: 1020;
                    &.show {
                        display: block;
                        float: left;
                    }
                }    
            }
            .btn.btn-secondary{
                width: 110px;
                margin-left: 20px;
                padding: 8px 28px;
            }
        }
       
    }
    span.strengthBydept {
        font-size: 16px;
        color: #28b1c2;
    }
}
#individualAppreciationSection{   
    width:100%;
    // height:650px;

}
.individualAppSection{
   width:100%;
   height:auto;
    padding:25px 30px;
    .row{
        margin-left:-30px;
        margin-right:-30px;
        [class*='col-']{
            padding-left:30px;
            padding-right:30px;
        }
    }
    .smallBarChart{
        .graphInfoNote{
            padding-bottom:20px;
        }
    }
    .graphsActionContainer{
        h3{            
            text-transform: unset;
            span{
                font-size:20px;
                color: #ff6e88;
                text-transform: capitalize;
                &.mailId{
                    text-transform: unset!important;
                }
            }
        }
    }
    .tabouter{
        padding-top:50px;
        .tab-content {
            max-height: 400px;
            overflow-y: scroll;
            overflow-x: hidden;
        }
    }
    .multiSelectwithlabel{
        padding-bottom: 30px;
        display: flex;
        align-items: baseline;
        justify-content: flex-end;
        label{
            @include montserrat-medium;
            color:$secondary-color-1;
            font-size:16px;
        }
        .selectInput {
            width: 50%;
            margin-left: 20px;
            .css-2b097c-container {
                border:1px solid #c2c2c2;
                .css-yk16xz-control{
                    border-bottom:none;
                }
            }
            .SelectedInput{
                position: absolute;
                z-index:1020;
            }
        }
    }

}
.input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%;
}

.input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.3rem;
    position: relative;
}
.input-range__track--active {
    background: #3f51b5;
}

.input-range__slider {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #3f51b5;
    border: 1px solid #3f51b5;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    outline: none;
    position: absolute;
    top: 50%;
    width: 1rem;
}
.input-range__label--value {
    position: absolute;
    top: -1.8rem;
}
.input-range__label {
    color: #aaaaaa;
    font-family: "Helvetica Neue", san-serif;
    font-size: 0.8rem;
    white-space: nowrap;
}
.appreciationFlow{
    padding:100px 30px;
    border-top: 2px solid $light-gray;    
    width:100%;
    .titleOuter{
        h2{
            margin-bottom:40px;
        }
    }
    .daywWiseFilter{
        padding-bottom:40px;
    }
    [class*='col-'] {
        padding-left: 30px;
        padding-right: 30px;
    }
    .graphsActionContainer{
        h3{
            margin-bottom:0px;
        }
        .exportContainer{
            padding-bottom:30px;
        }
    }
    .graphInfoNote{
        padding-bottom:30px;
    }
    .datalistContainer {
        max-height: 455px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .sankyrow{
        margin-left: -30px;
        margin-right: -30px;
    }
    // .sankyByComapny{
    //     .graphInfoNote{
    //         padding-bottom:50px;
    //     }
    // }
}

.heatMapsection{
    padding:80px 0px 0px;
    width:100%;
    .heatmapDatatitleContainer{
        width:100%;
    }
    svg{
        .domain{
            stroke:#595959 !important;
        }
    }
    
    .graphsActionContainer{
        padding-bottom:10px!important;
        .graphInfoNote{
            padding-bottom:0px!important;
        }
        .daywWiseFilter{
            padding-bottom:0px;
        }
        .exportContainer{
            padding-bottom:10px;
        }
    }
    .nav{
        //justify-content: space-between;
       
         &.nav-tabs{
             border-bottom: 1px;
             .nav-item{
                 cursor: pointer;
                 .nav-link{
                     border-radius:0px;
                     background-color:$light-gray;
                     color:$text-primary;
                     @include montserrat-semibold;
                     font-size:16px;
                     margin-left: 10px;
                     @include break-xs{
                         font-size:13px;
                         padding: .5rem 0.5rem;
                     }                
                    
                     &.active{
                         background-color:transparent;                         
                    }
                    
                 }
                 .disabled{
                     background-color:transparent;
                 }
                 &:first-child{
                     .nav-link{
                         margin-left: 0px;
                    }     
                }  
             } 
                     
             
         }
     }
     .tab-content{      
        .tab-pane { 
            width:100%;  
            border-top: 1px solid $light-gray;
            @include break-xs{
                font-size:13px;            
            }
            .appreciationList{
                padding: 20px 15px;
                display:flex;
                flex-flow:column nowrap;
                justify-content: center;
                width:100%;
                height:auto;
                &:nth-child(even){
                  background-color: $list-highlight;
                }
                .appreciation{
                    width:100%;
                    padding-bottom: 10px;
                  
                    p{
                        @include montserrat-regular;
                        @include break-xs{
                            font-size:13px;            
                        } 
                    }
                }
                .appreciationInfo{
                    display:flex;
                    width:100%;
                    justify-content: space-between;
                    .pointsCount{
                        @include montserrat-medium;
                        font-size:14px;
                        @include break-xs{
                               
                            font-size:12px;        
                        } 
                        span{
                            @include montserrat-semibold;
                            font-size:16px;
                            @include break-xs{                               
                                font-size:12px;        
                            } 
                        }
                    }
                    .Info{                        
                        display:flex;
                        justify-content: space-between;
                        .personName{
                            @include montserrat-medium;
                            font-size:14px;
                            @include break-xs{                               
                                font-size:10px;        
                            } 
                            span{
                                color:$linkblue;
                            }
                        }
                        .date{
                            padding-left:30px;
                            @include montserrat-medium;
                            font-size:14px;
                            @include break-xs{
                                padding-left:5px; 
                                font-size:10px;        
                            } 
                            span{
                                color:$linkblue;
                            }
                            
                        }
                    }
                }
            }
        }
        .row [class*='col-'] {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .graphsActionContainer{
        padding:30px 0px 20px;
    }
    .dataheader{
        padding:30px 0px 20px;
        border-bottom: 1px solid $light-gray;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span{
            @include montserrat-semibold;
            font-size:18px;
        }
        .number{
            color:#ff7b85;
            font-size:22px;
        }
        &.nodatafoundstate{
            margin-top:30px;
            border-bottom: none;
            border-top: 1px solid $light-gray;
        }
    }
   
}

.messageExLoader{
    width:100%;
    height:auto;
    div{
        width:100%;
        height:80px;
        margin-bottom:20px;
    }
}

.sunburst-container {
    ul li:nth-of-type(odd) {
        background-color:#F0F0F0;
    }
    ul {
        li {
            padding: 5px;
            label {
                margin-top: 5px;
            }
        }
    }
}

div#budgetUtilizationContainer {
    width: 100%;
    height: 100%;
}

.borderTopContainer {
    border-top: 2px solid $light-gray;
}

.budgetUtilization{
    width:100%;
    padding:30px 30px 30px;
    .row{
        margin-left:-30px;
        margin-right:-30px;
        [class*='col-']{
            padding-left:30px;
            padding-right:30px;
        }
    }
    .titleOuter{
        h2{
            margin-bottom:50px;
            span{
                text-transform: none;
            }
        }
    }
    .graphInfoNote{
        padding-bottom:10px;
    }

    // .budget-utilization-snapshot-div {
    //     width: auto;
    //     height: auto;
    // }
    // .col-sm-5 {
    //     width : 50%;  
    //     .graphsActionContainer {
    //         width : 100%;  
    //         display: flex;
    //         justify-content: space-between;
    //        }      
    // }

    .left-panel {
        float: left;
    }

    .right-panel {
        float: right;
    }
}

div.tooltip {
    position: absolute;
    text-align: left;
    width: 260px auto;
    height: auto;
    padding: 6px;
    background: white;
    border: 1px solid lightgray;
    pointer-events: none;
    font-size: 14px;
    color: #595959;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

div.redemption-tooltip {
    position: fixed;
    text-align: left;
    width: 260px auto;
    height: auto;
    padding: 6px;
    background: white;
    border: 1px solid lightgray;
    pointer-events: none;
    font-size: 14px;
    color: #595959;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    z-index: 1;
}

#content {
    width: 500px;
    // padding-left: 50px;
    // @include montserrat-semibold;
    color: #595959;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.5em;
}

.snapshotContainer{
    padding:15px 20px;
    height:100%;
    width: 100%;
}

path.line {
    transform: translate(30px, 0);
}

circle.dot {
    transform: translate(30px, 0);
}

.budgetUtilizationSec1 {
    width: 100%;
    height: auto;
    margin-bottom: 25px;
    margin-top: 2px solid #e4e4e4
}

// div#timeOfRedeemptionHeatmap {
//     margin-top: 33px;
// }

div#pointsAwardedAndRedeemedByDriver {
    margin-top: 30px;
}

.mt-100 {
    margin-top: 100px;
}

.mb-150 {
    margin-bottom: 80px;
}

g.mainbar {
    transform: translate(1px, 0);
}

.redemption-pref-beh{
    width:100%;
    padding:30px 30px 30px;
    .row{
        margin-left:-30px;
        margin-right:-30px;
        [class*='col-']{
            padding-left:30px;
            padding-right:30px;
        }
    }
    .titleOuter{
        h2{
            margin-bottom:50px;
            span{
                text-transform: none;
            }
        }
    }
    .graphInfoNote{
        padding-bottom:40px;
    }

    .left-panel {
        float: left;
    }

    .right-panel {
        float: right;
    }
}

.recharts-pie-label-line {
// display: none;
}

.pie-chart-redemption {
    width:100%; 
    height:100%;
    svg.recharts-surface {
        overflow:visible;
    }    
}

.pie-chart-legends {
    margin-bottom: 21px;
}

.vis-hidden {
    visibility: hidden;
}

.budget-utilization-snapshot-container {
    .recharts-wrapper {
        .recharts-legend-wrapper {
            top: 15px !important;
        }
    }
    
    #pointsAwardedAndRedeemedByDriver {
        .recharts-wrapper {
            .recharts-legend-wrapper {
                top: 15px !important;
                margin-top: 30px !important;
            }
        }   
    }
}

// .redemption-category {
// 	.recharts-wrapper {
// 		.recharts-surface {
// 			g.recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis {
// 				text.recharts-text.recharts-label {
//                     // transform: rotate(-90deg) translate(-218px, -154px) !important
//                     transform: rotate(-90, 34, 176.8) !important;
// 				}
// 			}
// 		}
// 	}
// }

// .modalgraphWrapper
// .redemption-category 
// .recharts-wrapper 
// .recharts-surface 
// g.recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis 
// text.recharts-text.recharts-label {
//     transform: rotate(-90deg) translate(-289px, -187px)
// }

// #categoryPointBasedRedemptionsContainer .recharts-wrapper 
// .recharts-surface 
// g.recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis 
// text.recharts-text.recharts-label {
//     transform: rotate(-90deg) translate(-218px, -154px)
// }