.login-wraper{
    border-radius: 1em;
    padding: 1em;
    position: absolute;
    top: 50%;
    left: 50%;
    width:380px;
    transform: translate(-50%, -50%);
    @include break-xs {
        width:100%;
    }

    .login-container{
       @include vertical-center;
       .login-icon{
           margin-bottom: 70px;
       }
        .loginform{
            @include vertical-center;
            width:100%;
            h4{
                margin-bottom: 30px;
            }
            form{
                .form-group{
                    .form-control{
                        height:65px;
                        @include montserrat-medium;
                    }
                    
                }
                .logInbtn{
                    &.btn-loader{
                        position: relative;
                        &:after {
                            content: " ";
                            display: block;
                            width: 26px;
                            height: 26px;
                            position: absolute;
                            top: 95px;
                            right: 10px;
                            bottom: 0;
                            margin: auto;
                            border-radius: 50%;
                            border: 4px solid #fff;
                            border-color: #fff transparent #fff transparent;
                            border-left-color: rgba(33, 35, 34, 0.07);
                            border-right-color: rgba(33, 35, 34, 0.07);
                            -webkit-animation: btn-loader 800ms linear infinite;
                            -moz-animation: btn-loader 800ms linear infinite;
                            -ms-animation: btn-loader 800ms linear infinite;
                            animation: btn-loader 800ms linear infinite;
                           
                        }
                        .btn{
                            pointer-events: none;
                            cursor: auto;
                        }    
                    }
                }                

            }

        }
    }
}