.searchfilterform{
    width:100%;
    background-color: #fef7f7;
    padding:30px 15px;
    top: 60px;
    z-index:1024;
    height: 0px;
    padding: 0px;
    opacity: 0;    
    position: sticky;
    z-index:-1;
    &.searchfilteropen{
        height: auto;
        padding:30px 15px;
        opacity: 1;
        transition: all 0.5s;
        -webkit-transition: opacity 2s, height 2s; /* For Safari 3.1 to 6.0 */
        transition:opacity 2s, height 2s;
        transform-origin: top;
        z-index:2;
    }
    @include break-md{
        padding:30px 0px;
        position: unset!important;
        top:0px;
    }
    @include break-xs{
        top:50px;
    }
    .filterForm{
        display:flex;
        flex-flow:row nowrap;
        @media screen and (max-width:1550px){
            flex-flow:row wrap;
        }
        @include break-md{
            display:block;
        }
        .nopad{
            padding-right: 10px!important;
        }
        .dateRange{
            .rdr-Calendar {
                position: absolute;
                z-index: 1026;
                top: 80px;
                left:30%;
                @include break-xs{
                    left:15%;
                }
            }
        }
        .selectInput{
            width:100%;
        }
        
    }
    .form-group{
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        margin-bottom:0px!important;
        @media (max-width: 1550px){
            margin-bottom:15px!important;
        }
        // padding-right: 10px!important;
        // padding-left: 0px !important;
        .dateInput{  
            align-items: baseline;
            @media (max-width:1550px){
                width:100%;
            }
            .form-control{
                @include montserrat-medium;
                font-size:14px;
                &.disable{
                    color: rgba(11, 45, 68, 0.5); 
                    cursor: default;
                }
            }                     
            .form-control,label{
                float:left;
                padding: 20px 0px;

            }
            label{
                @include montserrat-medium;
                border-bottom:1px solid $lighter-gray;
                padding:9px 10px;
                font-size:14px;
                margin-left:20px;
                &:first-child{
                    padding-left:0px;
                    margin-left:0px;
                }
                // @media (min-width:1550px){
                //     padding:10px 10px;
                // }
            }
        }
        .dropdown{
            width: 100%;
            .btn {
                padding:0px;
                margin-top:0px;
                height: calc(2.25rem + 2px);
                &.dropdown-toggle{
                    background-color: transparent;
                    border:none;
                    border-bottom:1px solid $black !important;
                    width:100%;
                    text-align: left;
                    &:after{
                        position: absolute;
                        right:10px;
                        bottom: 15px;
                    }
                }
            } 
            .dropdown-menu{
                right: 0px;
                background-color:$secondary-color-5;                            
                .dropdown-item{
                    color:$secondary-color-1!important;
                } 
            }   
        }
        label{
            color:$secondary-color-1;
            @include montserrat-medium;
            font-size: 16px;
            @include break-md{
                margin-bottom:0px;
            }
            @include break-xs{
                font-size: 14px;
            }

            
        }
        .form-control{
            width: 100%;
            padding: 0px;
            color:$secondary-color-1
        }
        button{
            &.btn{  
              
                width:auto;
                border-radius:0!important;
                &.btn-secondary{
                    border-radius:30px!important;
                    padding: 8px 36px; 
                    

                }
              
            }
        }
        // &:last-child{
        //     padding-left:0px;
        // }
    
        &.col-lg-6{         
            @media (min-width:1550px){
                max-width:20.5%;
            }
        }
        &.col-lg-3{        
            @media (min-width:1550px){
                // max-width:17.8%;
                max-width:14.5%;

            }
           
        }
        &.col-lg-2{                   
            @media (min-width:1550px){
                max-width:10%;
                padding-left:0px; 
            }
        }
    }
    
    
}