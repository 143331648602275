#sidebar {
    position: fixed;
    height: 100vh;
    background-color:$secondary-color-5;
    width: 87px;
    transition: all 0.3s;
    z-index:1025;   
    padding: 40px 25px;
    text-align: center;
    -webkit-transition: all 750ms ease-in-out; /* For Safari 3.1 to 6.0 */
    transition: all 750ms ease-in-out;
    overflow:hidden;
    @media screen and (max-width:992px){
        position: absolute;
        width:200px;
        height:100%;        
        margin-left:0px;
        display:block;
        opacity:1;
        transition: all 0.5s;
        -webkit-transition: opacity 2s, width 1s; /* For Safari 3.1 to 6.0 */
        transition: opacity 2s, width 1s;
        transform-origin: left;
        text-align: left;
        padding:10px 15px 15px;
    }
    &.active{
        width:230px;
        -webkit-transition: all 750ms ease-in-out; /* For Safari 3.1 to 6.0 */
        transition: all 750ms ease-in-out;
        padding: 40px 25px;
        @media screen and (max-width:992px){                     
            width:0px;
            height:auto;            
            display:none;
            opacity:0;
        }
        .sidebarMenulist{
            li{ 
               margin-top:25px;
                a{                    
                    text-align: left;
                    width:auto;
                    img{
                        display:none;
                        opacity: 0;                       
                        width: 0;
                        height:0px;
                        -webkit-transition: all 1500ms ease-in-out; /* For Safari 3.1 to 6.0 */
                        transition: all 1500ms ease-in-out;
                    }
                    span{
                        height:auto;
                        display:block;
                        opacity: 1;
                        width: 100%;
                        -webkit-transition: all 800s ease-in-out; /* For Safari 3.1 to 6.0 */
                        transition: all 800ms ease-in-out;
                    }
                }                
                &.active{
                    a{
                        border-bottom:2px solid $white;
                    }
                }
            } 
        }       
    }
    .sidebar-header{
        padding-bottom:20px;
        h3{
            color:$primary-color;
            font-size:16px;
        }

    }
    .sidebarMenulist{

        li{           
            display:block;
            margin-top:25px;
            width: 100%;
            float: left;
            @include break-md{
                margin-top: 40px;
            }  
           
            a{
                width:100%;
                padding:0px;
                display:block;
                text-align: left;
                text-transform: capitalize;
                @include montserrat-medium;
                font-size:16px;
                color: $secondary-color-1;
                float: left;
                &:hover,
                &:active{
                    @include montserrat-semibold;
                    border-bottom:2px solid $white;
                }
                @include break-md{
                   text-align:left;
                }

                span{
                    white-space: nowrap;
                    // display:none;
                    opacity: 0;
                    width: 0;
                    height:0;
                    display:block;
                    -webkit-transition: all 500ms ease-in-out; /* For Safari 3.1 to 6.0 */
                    transition: all 500ms ease-in-out;
                    @include break-md{
                        display:block;
                        opacity: 1;
                    }
                }
                img{
                    height: 28px;
                    width: auto;
                    margin-right: 5px; 
                    opacity: 1;
                    // display:block;  
                    float: right; 
                    -webkit-transition: all 1500ms ease-in-out; /* For Safari 3.1 to 6.0 */
                    transition: all 1500ms ease-in-out;
                    @include break-md{
                        display:none;
                    }                                 
                    
                }
            } 
            &.active{
                a{
                    @include montserrat-semibold;
                    border-bottom:none;
                }
            }  
            &.disable{
                a{
                    color:rgba(11, 45, 68,0.5); 
                    cursor:context-menu;
                    &:hover,
                    &:active{
                        @include montserrat-medium;
                        border-bottom:none;
                    }
                }
            } 
            
            // &.iconActive{
            //     a{
            //         text-align:right;
            //         span{
            //             display:none;
    
            //         }
            //         &:hover,
            //         &:active{
            //             @include montserrat-medium;
            //             border-bottom:none;
            //         }

            //     }
            //     &.active{
            //         a{
            //             @include montserrat-medium;
            //             border-bottom:none;
            //         }
            //     }  
              
            // }
        }
      
    }
}