$primary-color:#EA2C2D;
$secondary-color-1:#0b2d44;
$secondary-color-2:#64C9D3;
$secondary-color-3:#0F607D;
$secondary-color-4:#595959;
$secondary-color-5:#f5f1d9;
// $primary-hover:;
// $primary-dark:;

$lighter-skyblue: #e3f9fc;
$lighter-pink: #fde8eb;
$lighter-yellow: #fff2da;
$light-pink:#ffe2f4;
$lighetr-green: #def9ed; 
$light-green:#e2ffe6;
$marron:#a01125;         

$white:#ffffff;
$blue:#283593;
$black: #000000;
$light-gray : #e4e4e4;

$tab-recive: #6fd8e5;
$tab-given: #ff4052;
$list-highlight: #faf9f7;
$linkblue:#427bd9;
$lighter-gray: #c2c2c2;


$text-primary:#333333;
$text-secondary:#535353;

$para-primary:#757575;
$para-secondary:#949494;

$title-primary:#412894;
$title-secondary:#7B87CC;
$title-secondary-lighter:#B9C1EC;
$title-secondary-lightest:#F6F7FC;

$label-primary:#5862A3;
$checkbox-radio:#495187;


$well-bg:rgba(123, 135, 204, 0.07);

$link:$primary-color;
$link-hover:#7FCFB1;
$link-pressed:#55AD8C;
.link-disabled{
    opacity:0.4;
}
// @imagePath: ~"../../assets/images";

// Font includeed
@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');

.primaryFont{
    font-family: 'Poppins', sans-serif;
}
.secondaryFont{
    font-family: 'Open Sans', sans-serif;
}


@mixin transition {
    -webkit-transition: all 250ms ease-in-out;
    -moz-transition: all 250ms ease-in-out;
    -o-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
}

@mixin position-rel {
    position: relative;
}

@mixin d-block {
    display: block;
}

@mixin d-inline-block {
    display: inline-block;
}

@mixin d-none {
    display: none;
}

@mixin highlighted-text {
    color: $primary-color;
    font-weight: 600;
    font-size: 18px;
}

.primary-title {
    font-size: 42px;
    line-height: 45px;
    margin-bottom: 15px;
}

.secondary-title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
}

.ternary-title {
    font-size: 18px;
    letter-spacing: 1.5px;
    line-height: 29px;
    font-weight: 200;
}
.secondary-text {
    @extend .secondaryFont;
    color: $text-secondary;
    font-weight: 600;
    line-height: 27px;
    font-size: 20px;
}
.text-blue {
    color: $title-primary;
}

.text-light-blue {
    color: $title-secondary;
}
.form-element-border{
    border: 2px solid #E8EAF7;
    color:$text-secondary;
}
.form-title{
    font-size: 28px;
    line-height: 46px;
    margin-bottom: 15px;
    color:$title-primary;
    font-weight: normal;
}
.form-secondary-title{
    color: $para-primary;	
    font-size: 22px;	
    font-weight: 500;	
    line-height: 37px;	
    text-align: center;
}
.form-label{
    color:$label-primary;	
    @extend .primaryFont;	
    font-size: 16px;	
    letter-spacing: 0.75px;	
    line-height: 26px;
    margin-bottom:0;
}
.form-well-shadow{
    background-color: $white;
    box-shadow: 0 2px 15px 0 rgba(65,40,148,0.22);
}
.transition{
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}
html{
    body{
        input.form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $para-secondary;
        }
        input.form-control::-moz-placeholder { /* Firefox 19+ */
            color: $para-secondary;
        }
        input.form-control:-ms-input-placeholder { /* IE 10+ */
            color: $para-secondary;
        }
        input.form-control:-moz-placeholder { /* Firefox 18- */
            color: $para-secondary;
        }    
    }
}

.custom-check{
    padding: 0;
    [type="checkbox"]:checked,
    [type="checkbox"]:not(:checked),
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    [type="checkbox"]:checked + label,
    [type="checkbox"]:not(:checked) + label,
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label
    {
        position: relative;
        padding-left: 40px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        @extend .secondaryFont;
        font-size: 14px;	
        letter-spacing: 1px;	
        line-height: 28px!important;
        font-weight: normal;
        color:$text-secondary!important;
    }
    [type="checkbox"]:checked + label a,
    [type="checkbox"]:not(:checked) + label a,
    [type="radio"]:checked + label a,
    [type="radio"]:not(:checked) + label a{
        color: $primary-color;
        font-weight: 600;
    }
    [type="checkbox"]:checked + label:before,
    [type="checkbox"]:not(:checked) + label:before,
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before  {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 28px;
        height: 28px;
        @extend .form-element-border;
        background: $white;
        box-shadow: inset 1px 1px 7px 0 rgba(232,234,247,0.05);
    }
    
    [type="checkbox"]:checked + label:after,
    [type="checkbox"]:not(:checked) + label:after,
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 18px;
        height: 18px;
        background: $checkbox-radio;
        position: absolute;
        top: 5px;
        left: 5px;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        border-radius: 1px;
    }
    [type="checkbox"]:not(:checked) + label:after,
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    [type="checkbox"]:checked + label:after,
    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before,
    [type="radio"]:checked + label:after  {
        border-radius: 20px;
    }
}
.well{
    box-shadow: 0 2px 15px 0 rgba(65,40,148,0.22);
    background: $white;
    padding:40px;
}



.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}
.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  color: $text-secondary;
  width: 100%;
  height: 55px;
  
}


.select-options {
  display: none; 
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  margin-top:-2px;
  padding: 0;
  list-style: none;
  background: $white;
  border: 2px solid #A9B0DB;
  max-height: 135px;
  overflow: auto;
  &.at-bottom{
      top:auto;
      bottom: 100%;
      margin-bottom:-2px;
  }
  li {
    margin: 0;
    padding: 10px 15px;
    transition:all 0.15s ease-in;
    &:hover {
      background: $title-secondary-lightest;
    }
    &[rel="hide"] {
      display: none;
    }
  }
}