 
.tileContainer{
    padding:15px 20px;
    height:100%;  
    .percentContent{
        display:flex;
        flex-flow: column;
        align-items: center;
        height:100%;
        text-align: center;
        justify-content: space-between;
        
        .highlightNumber{
            @include montserrat-regular;
            font-size:38px;

        }
        .tileText{
            p{
                @include montserrat-medium;
                font-size:14px;
            }
            

        }
    }
    .listContent{
        @extend .percentContent;
        .title{
            @include montserrat-semibold; 
            font-size:16px;           
            margin-bottom: 10px;
            width:100%;
            text-align: left;
        }
        .textlist{
            width:100%;
           li{  
                display: block;
                font-size: 14px;
                margin-bottom: 5px;
                float: left;
                width: 100%;
                @include montserrat-medium;
                font-size:14px;
               .leftTitle{
                   float: left;
                   text-align: left;
                   width:70%;
                   color: $secondary-color-1;

               }
               .rightValue{
                   float:right;
                   @include montserrat-semibold;
                   color:$text-primary;
               }

           }

        } 
    }
    &.animated-background {
       height:140px;
        & :first-child{
            opacity:0;
        }
    }
}

@mixin TilesInnercolor($numColor){
    .highlightNumber{
        color:$numColor;
    }
    .listContent{
        .title{
            color:$numColor;
        }
    }    
}
.lighter-skyblue{
    @include bg-color($lighter-skyblue);
    @include TilesInnercolor(#11919c);
}
.lighter-pink{
    @include bg-color($lighter-pink); 
    @include TilesInnercolor($marron);
}
.lighter-yellow{
    @include bg-color($lighter-yellow);
    @include TilesInnercolor(#a17319);
}
.light-pink{
    @include bg-color($light-pink);
    @include TilesInnercolor(#ba3286);
}
.lighter-green{
    @include bg-color($lighetr-green);
    @include TilesInnercolor(#1ca46a);
}
.light-green{
    @include bg-color($light-green); 
    @include TilesInnercolor(#05ac1c);
}